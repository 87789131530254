<template>
  <div
    class="TypeToSelect"
    :class="{ 'TypeToSelect--switch-to-pool': switchToPool }"
  >
    <div :class="{ 'px-6': !!extraItemPadding }">
      <slot
        name="text-field"
        :on="textFieldOn"
        :attrs="textFieldAttrs"
      >
        <AppTextField
          v-bind="textFieldAttrs"
          v-on="textFieldOn"
        />
      </slot>
    </div>

    <v-list class="pa-0">
      <v-list-item
        v-for="item in filteredItems"
        :key="getKey(item)"
        :ripple="{ class: 'app-ripple' }"
        class="TypeToSelect__item py-4"
        :class="{
          'px-2': !extraItemPadding,
          'px-8': extraItemPadding,
          'TypeToSelect__item--selected': modelAsArray.includes(getKey(item))
        }"
        style="line-height: 20px; height: 76px"
        @mousedown="toggleSelection(getKey(item))"
        @click="/* */"
      >
        <div class="TypeToSelect__item-display flex-grow-1 align-self-stretch d-flex">
          <slot
            name="item"
            :item="item"
            :text="displayItem(item)"
          >
            {{ displayItem(item) }}
          </slot>
        </div>
        <v-icon
          :class="{
            'TypeToSelect__item-icon--show-on-hover':
              !switchToPool && !modelAsArray.includes(getKey(item))
          }"
          class="TypeToSelect__item-icon align-self-start"
          color="primary"
          v-text="getItemIcon(item)"
        />
      </v-list-item>
    </v-list>
  </div>
</template>

<script>
import * as R from 'ramda'

import { removeInPlace, wait } from '@/helpers'

export default {
  name: 'TypeToSelect',

  props: {
    value: { type: [String, Number, Array], default: () => [] }, // ID or Array of item ID's (if `multiple`)
    items: { type: Array, default: () => [] }, // Array of objects to select from
    itemText: { type: [String, Array, Function], default: 'name' },
    itemValue: { type: [String, Array, Function], default: 'id' },
    extraItemPadding: { type: Boolean, default: false },
    filter: {
      type: Function,
      default: (item, queryText, itemText) =>
        String(itemText).includes(queryText),
    },
    searchPlaceholder: { type: String, default() { return this.$t('layout.TypeToSearch') } },
    multiple: { type: Boolean, default: false },
    switchToPool: { type: Boolean, default: false },
  },

  data() {
    return {
      model: R.clone(this.value),
      searchQuery: '',
      forceShowPool: false,
    }
  },

  computed: {
    modelAsArray() {
      const { model } = this
      return R.is(Array, model) ? model : R.of(model)
    },

    textFieldOn() {
      return {
        input: q => { this.searchQuery = q || '' },
        focus: () => { this.forceShowPool = true },
        blur: this.onSearchBlur,
      }
    },

    textFieldAttrs() {
      return {
        value: this.searchQuery,
        outlined: true,
        dense: true,
        marginsWithHiddenDetails: 'mb-3',
        prependInnerIcon: 'mdi-magnify',
        placeholder: this.searchPlaceholder,
      }
    },

    searchTokens() {
      return (this.searchQuery || '')
        .trim()
        .split(/\s+?/g)
        .map(s => s.trim().toLocaleLowerCase())
        .filter(Boolean)
    },

    showSelected() {
      const { items, searchTokens, forceShowPool } = this
      if (!items) return null
      return !forceShowPool && !searchTokens.length
    },

    filteredItems() {
      const { items, modelAsArray, switchToPool, searchTokens, showSelected } = this
      if (!items) return null

      const filteredByVisibility = (() => {
        if (!switchToPool) return items
        if (showSelected) return items.filter(item => modelAsArray.includes(this.getKey(item)))
        return items.filter(item => !modelAsArray.includes(this.getKey(item)))
      })()
      return searchTokens.length
        ? filteredByVisibility.filter(item =>
          searchTokens.every(q =>
            this.filter(item, q, this.displayItem(item))))
        : filteredByVisibility
    },
  },

  watch: {
    items: {
      deep: true,
      handler() { this.searchQuery = '' },
    },

    value: {
      handler(value) {
        if (R.equals(value, this.model)) return
        this.model = R.clone(value)
      },
    },
    model: {
      handler(model) {
        if (R.equals(model, this.value)) return
        this.$emit('input', R.clone(model))
      },
    },
  },

  methods: {
    async onSearchBlur(_event = null, immediate = false) {
      if (!immediate) await wait(200)
      this.searchQuery = ''
      this.forceShowPool = false
    },

    toggleSelection(itemId) {
      if (this.modelAsArray.includes(itemId)) {
        if (this.multiple) removeInPlace(this.model, itemId)
        else {
          // this.model = null
          // do nothing, no deselect
        }
      } else {
        if (this.multiple) this.model.push(itemId)
        else this.model = itemId
      }
      this.onSearchBlur(null, true)
    },

    getKey(item) {
      const { itemValue } = this
      if (R.is(Function, itemValue)) return itemValue(item)
      if (R.is(Array, itemValue)) return R.path(itemValue, item)
      return item[itemValue]
    },
    displayItem(item) {
      const { itemText } = this
      if (R.is(Function, itemText)) return itemText(item)
      if (R.is(Array, itemText)) return R.path(itemText, item)
      return item[itemText]
    },

    getItemIcon(item) {
      const { switchToPool, showSelected, modelAsArray } = this
      if (switchToPool) {
        return showSelected ? 'mdi-minus-circle-outline' : 'mdi-plus-circle-outline'
      }
      return modelAsArray.includes(this.getKey(item))
        ? 'mdi-check-circle-outline'
        : 'mdi-plus-circle-outline'
    },
  },
}
</script>

<style lang="sass">
.TypeToSelect
  &__item, &__item-display
    overflow: hidden
    text-overflow: ellipsis

  &:not(&--switch-to-pool) &__item--selected
    background: #EBF5FF

  &__item-icon--show-on-hover
    opacity: 0

  &__item:hover &__item-icon--show-on-hover
    opacity: 1
</style>
