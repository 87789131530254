var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"TypeToSelect",class:{ 'TypeToSelect--switch-to-pool': _vm.switchToPool }},[_c('div',{class:{ 'px-6': !!_vm.extraItemPadding }},[_vm._t("text-field",function(){return [_c('AppTextField',_vm._g(_vm._b({},'AppTextField',_vm.textFieldAttrs,false),_vm.textFieldOn))]},{"on":_vm.textFieldOn,"attrs":_vm.textFieldAttrs})],2),_c('v-list',{staticClass:"pa-0"},_vm._l((_vm.filteredItems),function(item){return _c('v-list-item',{key:_vm.getKey(item),staticClass:"TypeToSelect__item py-4",class:{
        'px-2': !_vm.extraItemPadding,
        'px-8': _vm.extraItemPadding,
        'TypeToSelect__item--selected': _vm.modelAsArray.includes(_vm.getKey(item))
      },staticStyle:{"line-height":"20px","height":"76px"},attrs:{"ripple":{ class: 'app-ripple' }},on:{"mousedown":function($event){_vm.toggleSelection(_vm.getKey(item))},"click":function($event){/* */}}},[_c('div',{staticClass:"TypeToSelect__item-display flex-grow-1 align-self-stretch d-flex"},[_vm._t("item",function(){return [_vm._v(" "+_vm._s(_vm.displayItem(item))+" ")]},{"item":item,"text":_vm.displayItem(item)})],2),_c('v-icon',{staticClass:"TypeToSelect__item-icon align-self-start",class:{
          'TypeToSelect__item-icon--show-on-hover':
            !_vm.switchToPool && !_vm.modelAsArray.includes(_vm.getKey(item))
        },attrs:{"color":"primary"},domProps:{"textContent":_vm._s(_vm.getItemIcon(item))}})],1)}),1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }