<template>
  <TypeToSelect
    v-model="members"
    :filter="filter"
    :items="users"
    item-text="userLogin"
    multiple
    switch-to-pool
  >
    <template #item="{ item: user }">
      <UserAvatar
        :user="user"
        size="lg"
        class="mr-3"
      />
      <div class="flex-grow-1 flex-shrink-0 align-self-start">
        <div
          class="font-weight-medium mb-1"
          v-text="[
            user.firstName,
            user.lastName,
          ].join(' ').trim() || user.userLogin"
        />
        <div
          class="textSecondary--text"
          v-text="user.userEmail"
        />
      </div>
      <div
        class="mr-6 font-weight-medium align-self-start"
        style="line-height: 24px"
        v-text="user.isAdmin ? $t('user.Admin') : $t('user.User')"
      />
    </template>
  </TypeToSelect>
</template>

<script>
import * as R from 'ramda'

import TypeToSelect from '@/components/TypeToSelect'
import UserAvatar from '@/components/UserAvatar'

export default {
  name: 'UserGroupMemberSelector',

  components: {
    TypeToSelect,
    UserAvatar,
  },

  props: {
    value: { type: Array, default: () => [] }, // Array of user UUID's
  },

  data() {
    return {
      members: [...this.value],
    }
  },

  computed: {
    users() {
      const users = this.$store.getters['user/active']
      return users && R.pipe(
        R.map(user => ({
          ...user,
          _searchBy: [
            user.firstName,
            user.lastName,
            user.userLogin,
            user.userEmail,
            // user.id && String(user.id),
          ]
            .filter(Boolean)
            .map(s => s.toLocaleLowerCase())
            .join(' '),
        })),
        R.sortWith([
          // R.descend(R.prop('isAdmin')), // admins first
          // R.ascend(R.prop('isAdmin')), // admins last
          R.ascend(R.prop('firstName')),
          R.ascend(R.prop('lastName')),
          R.ascend(R.prop('userLogin')),
          R.ascend(R.prop('userEmail')),
        ]),
      )(users)
    },
  },

  watch: {
    value: {
      handler(value) {
        if (R.equals(value, this.members)) return
        this.members = [...value]
      },
    },
    members: {
      handler(members) {
        if (R.equals(members, this.value)) return
        this.$emit('input', [...members])
      },
    },
  },

  methods: {
    filter(user, q) {
      return user._searchBy.includes(q)
    },
  },
}
</script>
