<template>
  <ModalLayout
    v-model="isOpen"
    content-class="UserGroupDialog"
    :padding="0"
    :title="userGroupId ? $t('userGroup.EditGroup') : $t('userGroup.CreateGroup')"
    min-footer-height="64px"
    :max-width="957"
    persistent
  >
    <div class="UserGroupDialog__form">
      <div class="UserGroupDialog__left">
        <div class="UserGroupDialog__fieldset">
          <label>
            {{ $t('userGroup.Name') }}*
            <CommonTextField
              v-model="form.name"
              autofocus
              outlined
              dense
              :placeholder="$t('userGroup.GroupName')"
              class="mt-2"
              background="white"
              @keydown.enter="form.name && submit()"
            />
          </label>
          <label class="mt-4">
            {{ $t('userGroup.Description') }}
            <CommonTextarea
              v-model="form.description"
              outlined
              dense
              rows="1"
              auto-grow
              :placeholder="$t('userGroup.DescribeGroupBriefly')"
              class="mt-2"
              background="white"
              @keydown.enter="form.name && submit()"
            />
          </label>
        </div>
      </div>
      <div class="UserGroupDialog__right">
        <div class="d-flex justify-space-between mb-3">
          <span v-text="$t('userGroup.Participants')" />
          <span
            class="textSecondary--text"
            v-text="members ? $tc('user.usersN', members.length) : '…'"
          />
        </div>

        <UserGroupMemberSelector v-model="members" />
      </div>
    </div>

    <template #footer>
      <div class="mt-n2 d-flex flex-grow-1">
        <v-btn
          v-if="userGroupId"
          outlined
          color="error"
          min-width="40"
          width="40"
          :loading="deleting"
          @click="deleteGroup"
        >
          <v-icon>mdi-delete-outline</v-icon>
        </v-btn>

        <v-spacer />

        <v-btn
          depressed
          color="primary"
          :disabled="!form.name"
          :loading="saving"
          class="mr-4"
          @click="submit"
        >
          {{ $t('layout.Save') }}
        </v-btn>
        <v-btn
          outlined
          color="primary"
          @click="isOpen = false"
        >
          {{ $t('layout.Cancel') }}
        </v-btn>
      </div>
    </template>
  </ModalLayout>
</template>

<script>
import { ModalLayout } from '@hexway/shared-front'
import * as R from 'ramda'

import UserGroup from '@/store/orm/userGroup'

import UserGroupMemberSelector from '@/components/UserGroupMemberSelector'

export default {
  name: 'UserGroupDialog',

  components: {
    ModalLayout,
    UserGroupMemberSelector,
  },

  props: {
    dialogInstance: { type: Object, required: true },
    userGroupId: { type: String, default: null },
    initiallySelectedUserIds: { type: Array, default: () => [] },
  },

  data() {
    return {
      form: this.initForm(),
      members: this.initiallySelectedUserIds,

      saving: false,
      deleting: false,
    }
  },

  computed: {
    isOpen: {
      get() { return this.dialogInstance.isOpen },
      set(isOpen) { this.dialogInstance.onDialogModelInput(isOpen) },
    },

    userGroup() {
      return this.userGroupId && UserGroup.find(this.userGroupId)
    },

    savedMembers() {
      const { $store, userGroup } = this
      return userGroup
        ? $store.getters['user/groupMembers'](userGroup.id)
          ?.map?.(u => u.id) || []
        : []
    },
    membersToAdd() {
      const { members, savedMembers } = this
      return members.filter(mid => !savedMembers.includes(mid))
    },
    membersToRemove() {
      const { members, savedMembers } = this
      return savedMembers.filter(mid => !members.includes(mid))
    },
  },

  watch: {
    'userGroup.id': {
      immediate: true,
      handler() {
        this.form = this.initForm(this.userGroup)
        this.members = Array.from(new Set([...this.savedMembers, ...this.initiallySelectedUserIds]))
      },
    },
    isOpen: {
      immediate: true,
      handler(isOpen) {
        if (!isOpen) return
        this.form = this.initForm(this.userGroup)
        this.members = Array.from(new Set([...this.savedMembers, ...this.initiallySelectedUserIds]))
      },
    },
  },

  methods: {
    initForm(userGroup = null) {
      return userGroup
        ? R.pick(['name', 'description'], userGroup)
        : { name: '', description: '' }
    },

    submit() {
      const { userGroupId, form, membersToAdd, membersToRemove } = this

      this.saving = true
      UserGroup.dispatch(userGroupId ? '$update' : '$create', {
        userGroup: { ...form, id: userGroupId },
      })
        .then((userGroupId) => Promise.all([
          membersToAdd.length &&
            UserGroup.dispatch('$addUsers', { userGroupId, userIds: membersToAdd }),
          membersToRemove.length &&
            UserGroup.dispatch('$removeUsers', { userGroupId, userIds: membersToRemove }),
        ]))
        .then(() => {
          this.isOpen = false
          this.$emit('success')
        })
        .finally(() => { this.saving = false })
    },

    deleteGroup() {
      const { userGroupId } = this

      this.deleting = true
      this.$store.dispatch('confirm/openDialog', {
        title: this.$t('layout.AreYouSureQ'),
        subtitle: this.$t('layout.ActionImmediateM'),
        consentLabel: this.$t('layout.Delete'),
        consentProps: { color: 'error', depressed: true },
      })
        .then(agreed => agreed && UserGroup.dispatch('$delete', { userGroupId }))
        .then(() => { this.isOpen = false })
        .finally(() => { this.deleting = false })
    },
  },
}
</script>

<style lang="sass">
.UserGroupDialog
  &__form
    display: flex
    width: 100%
    min-height: min(421px, 70vh)

  &__left
    flex: 1
    padding: 28px
    background: #FAFAFA
    overflow: hidden auto
    height: 421px

  &__right
    flex: 0 0 457px
    padding: 28px
    background: white
    box-shadow: -2px 0px 0px 0px #EDEEF5
    overflow: hidden scroll
    height: 421px

  &__fieldset
    max-width: 312px
</style>
